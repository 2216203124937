import React, { Fragment } from 'react'
import { flushSync } from 'react-dom'
import styled from 'styled-components'
import _cloneDeep from 'lodash/cloneDeep'
import throttle from 'lodash/throttle'
import { get, headers } from '../lib/fetch'
import { SCREEN_SIZE } from '../lib/Media'
import Head from 'next/head'
import { getComponent } from '../components/LoadLayout'
import Layout from '../components/Layout/Layout'
import getLayoutUrl from '../lib/getLayoutUrl'
import { PAGE_TYPES } from '../lib/eventTrackerFunction'
import { cookieValuetoJSON } from '../lib/objectValidation'
import {
  CHECKOUT_ADDRESS,
  B2B_CHECKOUT_ADDRESS,
} from '../components/CheckoutAddressProvider/CheckoutAddressProvider'
import _get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import {
  RecommendedProductWrapper,
  StyledA,
  StyledContainer,
  ImageContainer,
  PlainDiv,
  OfferCardContainer,
} from '../components/RecommendedProduct/styles'
import { getActiveExperimentVariants } from '../lib/getActiveExperimentVariants'
import { getIosSmartBannerContent } from '../utils/getIosSmartBannerContent'
import {
  StyledInfo,
  CartSkeleton,
  SkeletonWrapper,
  LargeLine,
  SmallLine,
  ImageSkeleton,
  RecommendedProductHorizontal,
} from '../components/ProductCollection'
import { RECOMMENDED_FOR_YOU } from '../components/ProductCollection/RecommendationConstants'
import { getUserType } from '../utils/userType'
import { CTA_LOCATION_NAME } from '../lib/amplitude'
import AmendOrderNotificationBanner from '../components/AmendOrderNotificationBanner/AmendOrderNotificationBanner'
import { fetchOrders } from '../utils/amendOrderNotificationUtils'
import { AccountConsumer } from '../components/AccountProvider'
import { EventTrackingConsumer } from '../components/EventTrackingProvider/EventTrackingProvider'
import { productionCleanup } from '../lib/dataCleanup'
import { SPLIT_FEATURES } from '../utils/constants'
import { getConfigFor } from '../utils/configService'
import {
  initDyCookies,
  initDyParams,
  setDySessionFromLayout,
} from '../utils/DyUtils'

const SWIMLANES_TO_LOAD = 5
const FAKE_API_DELAY = 50

const StyledLayout = styled(Layout)`
  & main {
    ${SCREEN_SIZE.From.Desktop} {
      margin-top: 0;
    }
  }
`

const ComponetAdditionalProps = [
  {},
  {},
  {},
  {
    type: 'compact',
  },
  {},
  {},
]

const processData = info => {
  info.forEach((layout, index) => {
    if (
      layout?.name === 'ProductCollection' &&
      layout?.value?.title !== RECOMMENDED_FOR_YOU &&
      !layout?.value?.collection?.count
    ) {
      info.splice(index, 1)
    }
    if (
      layout?.name === 'CategoryCollection' &&
      layout?.value?.collection?.length === 0
    ) {
      info.splice(index, 1)
    }
  })
}
class Index extends React.Component {
  constructor(props) {
    super(props)
    const info = props.data?.data?.page?.layouts || []
    processData(info)
    this.state = {
      data: props.data,
      totalSwimlanes: info.length || SWIMLANES_TO_LOAD,
      shownSwimlanes: SWIMLANES_TO_LOAD,
      loading: false,
      showAmendOrderNotification: true,
    }
    this.onScroll = throttle(this.onScroll.bind(this), 200)
  }

  onScroll() {
    const { totalSwimlanes, shownSwimlanes, loading } = this.state
    if (shownSwimlanes >= totalSwimlanes) {
      window.removeEventListener('scroll', this.onScroll, false)
    }
    const wrapperElement = this.styledLayoutRef
    if (!wrapperElement) {
      this.setState({
        shownSwimlanes: totalSwimlanes,
        loading: false,
      })
    } else {
      const productListHeight = wrapperElement.offsetHeight
      const scrollY = window.scrollY || window.pageYOffset
      const threshold = 4000
      if (!loading && productListHeight <= scrollY + threshold) {
        this.setState({ loading: true })
        setTimeout(() => {
          this.setState({
            shownSwimlanes: shownSwimlanes + SWIMLANES_TO_LOAD,
            loading: false,
          })
        }, FAKE_API_DELAY)
      }
    }
  }

  renderComponent(name, data, config, index, pageType) {
    if (isEmpty(data)) {
      return
    }
    const { isEnabled: isCatBubbleEnabled } = getConfigFor(
      SPLIT_FEATURES.CAT_BUBBLE_ENABLED
    )
    const componentData = isCatBubbleEnabled ? data : null
    const Comp = getComponent(name, componentData)
    if (config.title) {
      const configTitle = config.title.replace(',', '').replace(/\s/g, '')
      config = {
        ...config,
        loc: `ProductWidget-${configTitle}`,
        pageType,
        ctaSublocation: [config.title],
      }
    }

    if (name === 'ImageSlideShow') {
      // additional config needed for customized ImageSlideShow
      config = {
        ...config,
        isBanner: true,
        arrow: {
          hide: true,
          displayFromDesktop: true,
        },
        pageType,
      }
      return (
        <Comp
          data={data}
          ctaLocation={{
            pageName: CTA_LOCATION_NAME.HOME,
          }}
          config={config}
          {...ComponetAdditionalProps[index]}
        />
      )
    } else if (Comp) {
      return (
        <Comp
          ctaLocation={{
            pageName: CTA_LOCATION_NAME.HOME,
          }}
          data={data}
          config={config}
          {...ComponetAdditionalProps[index]}
        />
      )
    }
  }

  fetchOrders = async () => {
    const { isLoggedIn, checkoutAddress } = this.props
    fetchOrders({
      isLoggedIn,
      checkoutAddress,
      onSuccess: ({ nextDeliveryOrder }) => {
        this.setState({ ...this.state, nextDeliveryOrder })
      },
      onError: ({ nextDeliveryOrder }) => {
        this.setState({ ...this.state, nextDeliveryOrder })
      },
    })
  }

  componentDidMount() {
    setDySessionFromLayout(this.props.data?.data?.page?.layouts)
    window.addEventListener('scroll', this.onScroll, false)
    this.fetchOrders()
    initDyCookies()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
  }

  async componentDidUpdate(prevProps) {
    // Calling DY for Headers
    const dyParams = initDyParams()
    const { checkoutAddress } = this.props
    if (
      prevProps.checkoutAddress &&
      String(prevProps.checkoutAddress.storeId) !==
        String(checkoutAddress.storeId)
    ) {
      const URL = 'layout/home/v2'
      const activeExperiments = getActiveExperimentVariants()
      let api = getLayoutUrl(URL, checkoutAddress)
      api +=
        api.indexOf('?') > -1
          ? `&includeTagDetails=true&orderType=${
              getUserType() === 'B2B' ? 'B2B' : 'DELIVERY'
            }`
          : `?includeTagDetails=true&orderType=${
              getUserType() === 'B2B' ? 'B2B' : 'DELIVERY'
            }`
      if (activeExperiments.length) {
        const { configuration } = getConfigFor(
          SPLIT_FEATURES.ALGOLIA_PERSONALISATION
        )
        const { personalisationSettings } = configuration
        api += `&experiments=${activeExperiments}&algopers=${personalisationSettings}`
      }

      // DY headers params
      api += dyParams
      const product = await get(api, {
        headers: headers(),
      })
      const data = _cloneDeep(product)
      // DY MetaData
      setDySessionFromLayout(product?.data?.page?.layouts)

      const layouts = JSON.parse(JSON.stringify(data.data.page.layouts))
      /**
       * Product data cleanup. Once API remove these extra fields we can remove this helper function
       */
      data.data.page.layouts = productionCleanup(layouts)

      const info = data?.data?.page?.layouts || []
      processData(info)
      window.scrollTo(0, 0)
      flushSync(() => {
        this.setState({
          data: data,
          totalSwimlanes:
            product.data?.page?.layouts.length || SWIMLANES_TO_LOAD,
          shownSwimlanes: SWIMLANES_TO_LOAD,
        })
      })
      // removing any existing scroll listener before adding new one on store change
      window.removeEventListener('scroll', this.onScroll, false)
      window.addEventListener('scroll', this.onScroll, false)

      this.fetchOrders()
    }
  }

  render() {
    const { organizationData, categoryList, iosSmartBannerContent } = this.props
    const { data, shownSwimlanes, loading } = this.state
    const content = data.data.page.layouts.slice(0, shownSwimlanes)
    const pageType = data.data.page.name
      ? PAGE_TYPES[data.data.page.name]
      : undefined
    let preloadImageUrl = null
    if (
      content.length &&
      content[0].name === 'ImageSlideShow' &&
      content[0]?.value?.images
    ) {
      const imageUrl = content[0]?.value?.images[0]?.imageUrl
      if (imageUrl) {
        preloadImageUrl = imageUrl
      }
    }

    return (
      <React.Fragment>
        <Head>
          <meta
            name="description"
            key="meta_description"
            content="Enjoy low prices and access online-exclusive products and offers! Get $10 off your first online order and enjoy free delivery with min. spend $59."
          />
          <meta name="apple-itunes-app" content={iosSmartBannerContent} />
          <title>NTUC FairPrice | Your #1 Grocery Retailer</title>
          {preloadImageUrl && (
            <link
              rel="preload"
              href={`${preloadImageUrl}?q=70`}
              as="image"
              imageSrcSet={`${preloadImageUrl}?q=70 1200w, ${preloadImageUrl}?w=200&q=70 200w, ${preloadImageUrl}?w=400&q=70 400w, ${preloadImageUrl}?w=800&q=70 800w, ${preloadImageUrl}?w=1024&q=70 1024w`}
            />
          )}
        </Head>
        <div ref={node => (this.styledLayoutRef = node)}>
          <StyledLayout
            organizationData={organizationData}
            categoryList={categoryList}
            showPostalCodeModule
          >
            {!content ? (
              <div>Loading...</div>
            ) : (
              <React.Fragment>
                <AmendOrderNotificationBanner
                  show={this.state.showAmendOrderNotification}
                  onClose={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.setState({
                      ...this.state,
                      showAmendOrderNotification: false,
                    })
                  }}
                  track={this.props.track}
                  order={this.state.nextDeliveryOrder}
                />
                {content.map((layout, index) => (
                  <React.Fragment key={'layout' + index}>
                    {this.renderComponent(
                      layout.name,
                      layout.value,
                      layout.data,
                      index,
                      pageType
                    )}
                  </React.Fragment>
                ))}
                {loading && (
                  <Fragment>
                    {[0, 1, 2, 3, 4].map((i, itemIndex) => (
                      <SkeletonWrapper key={itemIndex}>
                        {[...Array(6).keys()].map((item, index) => (
                          <RecommendedProductHorizontal
                            key={`wrap-comp-${item}`}
                          >
                            <RecommendedProductWrapper
                              className="product-container"
                              key={`product-${index}`}
                            >
                              <StyledA>
                                <StyledContainer>
                                  <PlainDiv>
                                    <ImageContainer>
                                      <ImageSkeleton />
                                    </ImageContainer>
                                  </PlainDiv>
                                  <StyledInfo className="skeleton">
                                    <SmallLine />
                                    <LargeLine />
                                    <LargeLine />
                                  </StyledInfo>
                                  <OfferCardContainer />
                                  <CartSkeleton />
                                </StyledContainer>
                              </StyledA>
                            </RecommendedProductWrapper>
                          </RecommendedProductHorizontal>
                        ))}
                      </SkeletonWrapper>
                    ))}
                  </Fragment>
                )}
              </React.Fragment>
            )}
          </StyledLayout>
        </div>
      </React.Fragment>
    )
  }
}

const IndexWrapper = props => (
  <AccountConsumer>
    {({ isLoggedIn }) => (
      <EventTrackingConsumer>
        {({ track }) => (
          <Index {...props} isLoggedIn={isLoggedIn} track={track} />
        )}
      </EventTrackingConsumer>
    )}
  </AccountConsumer>
)

IndexWrapper.getInitialProps = async ctx => {
  // Calling DY for Headers
  const dyParams = initDyParams(ctx)
  const iosSmartBannerContent = getIosSmartBannerContent(ctx)
  const b2cCheckoutAddressCookie = cookieValuetoJSON(ctx, CHECKOUT_ADDRESS)
  const b2bCheckoutAddressCookie = cookieValuetoJSON(ctx, B2B_CHECKOUT_ADDRESS)
  const user_type = getUserType(ctx)
  const b2CCheckoutAddress = ctx.res
    ? ctx.res.locals.checkoutAddress
    : window.__NEXT_DATA__.props.checkoutAddress

  const b2bCheckoutAddress = ctx.res
    ? ctx.res.locals.b2bCheckoutAddress
    : window.__NEXT_DATA__.props.b2bCheckoutAddress
  let checkoutAddress
  if (user_type === 'B2B') {
    checkoutAddress = b2bCheckoutAddressCookie || b2bCheckoutAddress
  } else {
    checkoutAddress = b2cCheckoutAddressCookie || b2CCheckoutAddress
  }
  const remoteConfig = ctx.res
    ? _get(ctx, 'res.locals.remoteConfigData')
    : window.__NEXT_DATA__.props.remoteConfig

  const URL = 'layout/home/v2'
  const activeExperiments = getActiveExperimentVariants()
  let api = getLayoutUrl(URL, checkoutAddress)
  api +=
    api.indexOf('?') > -1
      ? `&includeTagDetails=true&orderType=${
          user_type === 'B2B' ? 'B2B' : 'DELIVERY'
        }`
      : `?includeTagDetails=true&orderType=${
          user_type === 'B2B' ? 'B2B' : 'DELIVERY'
        }`
  if (activeExperiments.length) {
    const { configuration } = getConfigFor(
      SPLIT_FEATURES.ALGOLIA_PERSONALISATION
    )
    const { personalisationSettings } = configuration
    api += `&experiments=${activeExperiments}&algopers=${personalisationSettings}`
  }
  // DY headers params
  api += dyParams
  const data = await get(api, {
    headers: headers(ctx),
  })

  const layouts = JSON.parse(JSON.stringify(data.data.page.layouts))
  /**
   * Product data cleanup. Once API remove these extra fields we can remove this helper function
   */
  data.data.page.layouts = productionCleanup(layouts)

  return {
    data,
    remoteConfig,
    iosSmartBannerContent,
  }
}

IndexWrapper.defaultProps = { data: {} }

export default IndexWrapper
